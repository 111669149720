<template>
  <div id="privacy-kr">
    <div style="overflow-x: auto;">
      <p style="margin:0pt; text-align:center">
        <span style="font-family:'맑은 고딕'; font-size:16pt; font-weight:bold"
          >개인정보 처리방침</span
        >
      </p>
      <h3
        style="background-color:#ffffff; font-weight:normal; margin:7.5pt 0pt 0pt 10.25pt; orphans:0; padding-left:18.25pt; page-break-after:avoid; text-align:justify; text-indent:-18.25pt; widows:0"
      >
        <a name="_Toc107041916"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >1. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >총칙</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:1.55pt; widows:0"
      >
        <span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.3pt"
          >㈜팡스카이(이하 ‘회사’라 합니다.)는 </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.3pt"
          >이용자의 자유와</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.3pt"
        >
          권리 보호를 위해 「개인정보 보호법」 및 </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.3pt"
          >관계</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.3pt"
        >
          법령이</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
        </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
          >정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
          >있습니다</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
          >. 이에 「개인정보 보호법」</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
        </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.4pt"
          >제30조에 따라 정보주체에게 개인정보 처리에 관한 </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.4pt"
          >절차</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.4pt"
        >
          및 기준을 안내하고, 이와 관련한 고충을 신속하고</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
          원활하게 처리할 수 있도록 하기 </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >위하여</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
          다음과 같이 개인정보 처리방침을 수립·공개합니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:14.65pt; text-align:justify; text-indent:-14.65pt; widows:0"
      >
        <span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >&#xa0;</span
        >
      </p>
      <h3
        style="background-color:#ffffff; font-weight:normal; margin:7.5pt 0pt 0pt 10.25pt; orphans:0; padding-left:18.25pt; page-break-after:avoid; text-align:justify; text-indent:-18.25pt; widows:0"
      >
        <a name="_Toc107041917"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >2. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >수집하는 개인정보 항목 및 이용목적</span
          ></a
        >
      </h3>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'맑은 고딕'; font-size:10pt">①</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">
          회사는 회원가입 시 서비스 제공을 위해 필요한 최소한의 개인정보만을
          수집합니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:13.85pt; text-align:justify; text-indent:-13.85pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">② </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
          >회사는 처리하고 있는</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
        >
          개인정보는 다음의 목적 이외의 용도로 사용되지 않으며 이용 목적이
          변경되는 경우에</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
          >는</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
        >
          개인정보 보호법 제18조에 따라 </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
          >상세하게 안내하고 이용자로부터 별도의 동의를 받습니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:13.85pt; text-align:justify; text-indent:-13.85pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">③</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
          >이용자가 회사의 서비스를 이용하기 위해서는 회원가입 시 개인정보를
          입력하셔야 하며, 회사는 필수</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >입력항목과 선택</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
        </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >입력항목으로 구분하여 개인정보를 수집하고 있습니다. 필수</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
        </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >입력항목은 회원가입을</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >위해 반드시 제공해야 하는 개인정보이며, 선택</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
        </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >입력항목은 </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >제공</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >하지 않으</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >시더라도</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
          회원가입이 가능합니다.</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">
          필수항목과 선택항목은 다음과 같습니다.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'맑은 고딕'; font-size:9pt">&#xa0;</span>
      </p>
      <table
        cellspacing="0"
        cellpadding="0"
        style="border-collapse:collapse; margin-left:0pt; width:492.05pt"
      >
        <thead>
          <tr style="height:3pt">
            <td
              style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:52.75pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:9pt; font-weight:bold"
                  >수집 서비스</span
                >
              </p>
            </td>
            <td
              colspan="2"
              style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:106.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:9pt; font-weight:bold"
                  >구분</span
                >
              </p>
            </td>
            <td
              colspan="2"
              style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:155.7pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:9pt; font-weight:bold"
                  >개인정보수집항목</span
                >
              </p>
            </td>
            <td
              style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:84.55pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:9pt; font-weight:bold"
                  >수집목적</span
                >
              </p>
            </td>
            <td
              style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:9pt; font-weight:bold"
                  >보유기간</span
                >
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr style="height:22.7pt">
            <td
              rowspan="8"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:52.75pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-1.1pt"
                  >Panggame</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-1.1pt"
                >
                  사이트,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-1.1pt"
                >
                </span>
              </p>
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >묵혼 온라인</span
                >
              </p>
            </td>
            <td
              colspan="2"
              rowspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:106.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.9pt"
                  >회원식별 및 관리</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">필수</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >전화번호,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >이메일,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >비밀번호,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >닉네임</span
                >
              </p>
            </td>
            <td
              rowspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:84.55pt"
            >
              <p style="margin:0pt; orphans:0; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >이용자 식별,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >본인인증,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >게임서비스 제공</span
                >
              </p>
            </td>
            <td
              rowspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >회원탈퇴 시</span
                >
              </p>
            </td>
          </tr>
          <tr style="height:22.7pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">선택</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">없음</span>
              </p>
            </td>
          </tr>
          <tr style="height:22.7pt">
            <td
              colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:106.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >법정대리인의 </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.3pt"
                  >동의 여부 확인</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">필수</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >법정대리인</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt">
                  정보</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:84.55pt"
            >
              <p style="margin:0pt; orphans:0; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.8pt"
                  >만</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.8pt"
                >
                  14세 미만 아동의 법정대리인 본인확인 및 법정대리인의 동의의사
                  확인</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">2년</span>
              </p>
            </td>
          </tr>
          <tr style="height:22.7pt">
            <td
              rowspan="3"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:39.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >유료 서비스 결제</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:56.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >계좌 이체 시</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">필수</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.1pt"
                  >거래은행명</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.1pt"
                  >, 계좌번호, 거래자 이름</span
                >
              </p>
            </td>
            <td
              rowspan="3"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:84.55pt"
            >
              <p style="margin:0pt; orphans:0; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >유료 서비스 </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >구매 및 요금결제</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">5년</span>
              </p>
            </td>
          </tr>
          <tr style="height:22.7pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:56.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-1pt"
                  >신용카드 결제 시</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">필수</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">신용</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt">
                  카드 종류, 카드 번호, 유효 기간, 비밀번호, 할부 기간</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">5년</span>
              </p>
            </td>
          </tr>
          <tr style="height:22.7pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:56.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.3pt"
                  >휴대폰 결제 시</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">필수</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >휴대폰</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt">
                  번호, 결제자 이름</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">5년</span>
              </p>
            </td>
          </tr>
          <tr style="height:22.7pt">
            <td
              rowspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:39.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >고객상담</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt"> </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.7pt"
                  >및 민원처리</span
                >
              </p>
            </td>
            <td
              rowspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:56.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">고객</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt">
                  상담 및</span
                >
              </p>
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.8pt"
                  >민원처리</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.8pt"
                >
                  서비스</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">필수</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >이메일</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt">, </span
                ><span style="font-family:'맑은 고딕'; font-size:8pt"
                  >계정정보,</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt">
                  CI, DI</span
                >
              </p>
            </td>
            <td
              rowspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:84.55pt"
            >
              <p style="margin:0pt; orphans:0; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >고객문의</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt">
                  대응, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리,
                  고지사항 전달</span
                >
              </p>
            </td>
            <td
              rowspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">3년</span>
              </p>
            </td>
          </tr>
          <tr style="height:22.7pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">선택</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.3pt"
                  >문의에</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.3pt"
                >
                  대한 확인 및 답변을 위하여 필요한 정보</span
                >
              </p>
            </td>
          </tr>
          <tr style="height:4.25pt">
            <td
              rowspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:52.75pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >모바일 서비스 플랫폼(게임)</span
                >
              </p>
            </td>
            <td
              colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:106.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.9pt"
                  >회원식별 및 관리</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">필수</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >계정 연동 키값(구글, 페이스북, 애플 게임센터)</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:84.55pt"
            >
              <p style="margin:0pt; orphans:0; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >이용자 식별,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >게임서비스 제공</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >회원탈퇴 시</span
                >
              </p>
            </td>
          </tr>
          <tr style="height:4.25pt">
            <td
              colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:106.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.9pt"
                  >게임상태</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.9pt"
                  >, </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.9pt"
                  >이벤트 관리</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">선택</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >웹푸시</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:84.55pt"
            >
              <p style="margin:0pt; orphans:0; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >이벤트, 소식, 게임상태, 광고 정보를 전송</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt"
                  >회원탈퇴 시 또는 수신 동의 철회 시</span
                >
              </p>
            </td>
          </tr>
          <tr style="height:4.25pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:52.75pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">공통</span>
              </p>
            </td>
            <td
              colspan="2"
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:106.2pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.4pt"
                  >서비스 이용과정에서 자동으로</span
                ><span style="font-family:'맑은 고딕'; font-size:8pt">
                  수집되는 정보</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:18.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">필수</span>
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:125.95pt"
            >
              <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >IP Address, 쿠키, 방문 일시, 서비스 이용기록</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >불랑이용기록,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >일 </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                  >기기정보(고유기기식별정보, OS버전</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.2pt"
                >
                  등)</span
                >
              </p>
              <p
                style="margin:0pt 0pt 0pt 10.85pt; orphans:0; text-align:justify; text-indent:-7.05pt; widows:0"
              >
                <span style="font-family:'Malgun Gothic'; font-size:7pt">※</span
                ><span style="font:7.0pt 'Times New Roman'"> </span
                ><span style="font-family:'맑은 고딕'; font-size:7pt"
                  >쿠키의 경우,</span
                ><span style="font-family:'맑은 고딕'; font-size:7pt"> </span
                ><span style="font-family:'맑은 고딕'; font-size:7pt"
                  >로그아웃 시 또는 브라우저 종료 시 만료</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:84.55pt"
            >
              <p style="margin:0pt; orphans:0; widows:0">
                <span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.5pt"
                  >서비스 안정성 확보 및 맞춤 서비스 제공,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.5pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.7pt"
                  >부정이용자 방지,</span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.7pt"
                >
                </span
                ><span
                  style="font-family:'맑은 고딕'; font-size:8pt; letter-spacing:-0.7pt"
                  >이용자 식별,</span
                >
              </p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:38.1pt"
            >
              <p style="margin:0pt; orphans:0; text-align:center; widows:0">
                <span style="font-family:'맑은 고딕'; font-size:8pt">3년</span>
              </p>
            </td>
          </tr>
        </tbody>
        <tr style="height:0pt">
          <td style="width:63.55pt; border:none"></td>
          <td style="width:50pt; border:none"></td>
          <td style="width:67pt; border:none"></td>
          <td style="width:29.75pt; border:none"></td>
          <td style="width:136.75pt; border:none"></td>
          <td style="width:95.35pt; border:none"></td>
          <td style="width:48.9pt; border:none"></td>
        </tr>
      </table>

      <p
        style="margin:0pt 0pt 0pt 32.2pt; orphans:0; text-align:justify; text-indent:-12.2pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">※</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
          >외부</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.2pt"
        >
          플랫폼을 통한 계정 연동 시 ID, 닉네임, 프로필, 연동 키값 등을 제공받을
          수 있으나, 회사는</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">
          연동 키값만 수집 및 이용하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 32.2pt; orphans:0; text-align:justify; text-indent:-12.2pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >※</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.6pt"
          >모바일 게임 탈퇴 시 닉네임은 불,편법 행위의 방지 및 대응을 목적으로
          서비스 종료 시까지 보관됩니다.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'맑은 고딕'; font-size:9pt">&#xa0;</span>
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:13.85pt; text-align:justify; text-indent:-13.85pt; widows:0"
      >
        <span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >④ 만</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
          14세 미만 아동은 이용 연령 등급에 따른 게임, </span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
          >사전등록,</span
        ><span
          style="font-family:'맑은 고딕'; font-size:10pt; letter-spacing:-0.1pt"
        >
          모바일 이메일 회원 등 일부 서비스가 제한될 수 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:17.25pt; text-align:justify; text-indent:-17.25pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">⑤ </span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"
          >이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보 (민족, 사상
          및 신조, 출신지 및 본적지, 정치적 성향 및 범죄 기록, 건강 상태 및
          성생활 등)는 수집하지 않습니다.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'맑은 고딕'; font-size:9pt">&#xa0;</span>
      </p>
      <h3
        style="background-color:#ffffff; font-weight:normal; margin:7.5pt 0pt 0pt 4.5pt; orphans:0; padding-left:18.25pt; page-break-after:avoid; text-align:justify; text-indent:-18.25pt; widows:0"
      >
        <a name="_Toc107041918"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >3. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >개인정보의 이용 및 보유기간</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >① 회사는 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >이용자가 이용자</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >자격을 유지하고 있는 동안에는 이용자가 회사에 제공한 개인정보를 계속
          보유</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">하며,</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >개인정보의 수집 및 이용목적 달성 후에는 수집한 개인정보를 지체없이
          파기합니다.</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">단,</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
          >다음과 같은 사유에 해당되는 경우 기재된 기한까지 개인정보를 외부와
          차단된 별도 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
          >DB </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
          >또는 테이블에</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          분리 보관됩니다.</span
        >
      </p>
      <table
        cellspacing="0"
        cellpadding="0"
        style="border-collapse:collapse; margin-left:0pt; width:494.75pt"
      >
        <tr style="height:17pt">
          <td
            style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:166.4pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold"
                >법률</span
              >
            </p>
          </td>
          <td
            style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:244pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold"
                >보관항목</span
              >
            </p>
          </td>
          <td
            style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:51.2pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold"
                >보유기간</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:17pt">
          <td
            rowspan="4"
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:166.4pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >전자상거래</span
              ><span style="font-family:'Malgun Gothic'; font-size:10pt">
                등에서의 소비자 보호에 관한 법률</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:244pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >계약</span
              ><span style="font-family:'Malgun Gothic'; font-size:10pt">
                또는 청약 철회 등에 관한 기록</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:51.2pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >5년</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:17pt">
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:244pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >대금</span
              ><span style="font-family:'Malgun Gothic'; font-size:10pt">
                결제 및 재화 등의 공급에 관한 기록</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:51.2pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt">5</span
              ><span style="font-family:'Malgun Gothic'; font-size:10pt"
                >년</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:17pt">
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:244pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >소비자의</span
              ><span style="font-family:'Malgun Gothic'; font-size:10pt">
                불만 또는 분쟁 처리에 관한 기록</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:51.2pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >3년</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:17pt">
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:244pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >표시</span
              ><span style="font-family:'Malgun Gothic'; font-size:10pt"
                >/광고에 관한 기록</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:51.2pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >6개월</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:17pt">
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:166.4pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >통신비밀보호법</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:244pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >서비스</span
              ><span style="font-family:'Malgun Gothic'; font-size:10pt">
                이용기록, 접속기록, 접속 IP정보</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:51.2pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >3개월</span
              >
            </p>
          </td>
        </tr>
      </table>

      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">②</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >개인정보제공에 대해 동의철회(회원탈퇴)할 경우 즉시 수집된 개인정보를
          즉시 파기하며, </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >어떠한 목적으로도 사용할 수 없도록 합니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">③ </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >회사에서 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >제공하는 서비스에 이용자가 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >1년</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          동안 로그인 등 이용 기록이 없을 경우, 이용자의 계정을</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          휴면계정 처리하고 일반 이용자의 개인정보와 별도 분리·저장됩니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >- 휴면 계정처리 예정일로부터 30일 이전에 해당 사실을 전자메일, 서면,
          SMS 중 하나의 방법으로 이용자에게 통지합니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >- 분리·저장된 개인정보는 관련 법령에 특별한 규정이 있는 경우를
          제외하고 해당 개인정보를 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >이용하거나 제공하지 않으며, 관련 법령에 따라 일정기간 동안 보유되고
          해당 기간 종료 후 파기됩니다.</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >그러나, 파기되지 않은 개인정보는 해당 이용자의 요청에 따라 서비스
          이용을 재개하는 시점에 다시</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          제공됩니다.</span
        >
      </p>
      <h3
        style="background-color:#ffffff; font-weight:normal; margin:7.5pt 0pt 0pt 10.25pt; orphans:0; padding-left:18.25pt; page-break-after:avoid; text-align:justify; text-indent:-18.25pt; widows:0"
      >
        <a name="_Toc107041919"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >4. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >개인정보의 제3자 제공</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >① 회사는 정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조
          및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">-</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >이용자가 서비스 이용중 제3자 제공에 동의한 경우</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">- </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >법령의 규정에 의거, 수사 목적으로 법령에 정해진 절차와 방법에 따라
          수사기관의 요구가 있을 경우</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >- 기타 관계 법령에서 정한 절차에 따른 요청이 있는 경우</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >- 그러나 예외 사항에서도 관계 법령에 의하거나 수사기관의 요청에 의해
          정보를 제공한 경우에는 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.4pt"
          >이를 당사자에게 고지하는 것을 원칙으로 운영하고 있습니다. 그러나
          법률상의 근거에 의해 부득이하게 고지를 하지 못할 수도 있습니다. 본래의
          수집목적 및 이용목적에 반하여 무분별하게 정보가 제공되지 않도록 최대한
          노력하겠습니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >②</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
        >
          매각, 인수 합병 등 서비스 제공자의 권리와 의무가 완전 승계, 이전되는
          경우 반드시 사전에 정당한 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >사유와 절차에 대해 상세하게 고지할 것이며 이용자의 개인정보에 대한
          동의 철회의 선택권을 부여합니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >&#xa0;</span
        >
      </p>
      <h3
        style="background-color:#ffffff; font-weight:normal; margin:7.5pt 0pt 0pt 10.25pt; orphans:0; padding-left:18.25pt; page-break-after:avoid; text-align:justify; text-indent:-18.25pt; widows:0"
      >
        <a name="_Toc107041920"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >5. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >개인정보처리 위탁</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >① </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >회사는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          서비스 향상을 위하여 개인정보를 제한된 범위에서 타 업체에 위탁하여
          관리하도록 할 수 있습니다.</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
        >
        </span>
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >②</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >회사가 개인정보 처리을 위탁하는 업체는 아래와 같으며 관계 법령에 따라
          위탁 계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 조치를 하고
          있습니다. 또한, 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이
          본 개인정보 처리방침을 통하여 공개하도록 하겠습니다</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >.</span
        >
      </p>
      <table
        cellspacing="0"
        cellpadding="0"
        style="border-collapse:collapse; margin-left:0pt"
      >
        <tr>
          <td
            style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold; letter-spacing:-0.2pt"
                >관련 서비스</span
              >
            </p>
          </td>
          <td
            style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold; letter-spacing:-0.2pt"
                >수탁자</span
              >
            </p>
          </td>
          <td
            style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold; letter-spacing:-0.2pt"
                >서비스 내용</span
              >
            </p>
          </td>
          <td
            style="background-color:#f1f1f1; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold; letter-spacing:-0.2pt"
                >개인정보</span
              ><span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold; letter-spacing:-0.2pt"
              >
              </span
              ><span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold; letter-spacing:-0.2pt"
                >보유</span
              ><span
                style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:bold; letter-spacing:-0.2pt"
                >기간</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            rowspan="2"
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:center; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >묵혼 온라인</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >나이스신용평가정보</span
              ><span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >㈜</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >이용자 본인 확인</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >별도 저장하지 않음</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >KG이니시스</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >유료서비스 결제처리 및 요금정산</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >결제일 기준 </span
              ><span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >5</span
              ><span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >년</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >묵혼 온라인</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >슈퍼진스</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
                >게임 서비스 관리 및 개발</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >회원 탈퇴 시</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >베</span
              ><span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
                >스트라이전기</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >엔투어스소프트</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
                >게임 서비스 관리 및 개발</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >회원 탈퇴 시</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >용의분노</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >하이난선예</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
                >게임 서비스 관리 및 개발</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >회원 탈퇴 시</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
                >포트리스 배틀로얄</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >씨씨알컨텐츠트리</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
                >게임 서비스 관리 및 개발</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >회원 탈퇴 시</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
                >드래곤라자온라인</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'Malgun Gothic'; font-size:10pt"
                >빌리온게임즈</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
                >게임 서비스 관리 및 개발</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >회원 탈퇴 시</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >상담게시판</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >N</span
              ><span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >HN</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >상담게시판 서비스 제공</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >회원 탈퇴 시</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:66.3pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >홈페이지</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:109.7pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span style="font-family:'맑은 고딕'; font-size:10pt"
                >Chengdu Yunhaiyou Technology</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:116.75pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="color:#474747; font-family:'Malgun Gothic'; font-size:10pt"
                >홈페이지 유지 및 개발</span
              >
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:123.5pt"
          >
            <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
              <span
                style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
                >회원 탈퇴 시</span
              >
            </p>
          </td>
        </tr>
      </table>

      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 9.9pt; orphans:0; padding-left:16.5pt; page-break-after:avoid; text-align:justify; text-indent:-16.5pt; widows:0"
      >
        <a name="_Toc107041921"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >6. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >개인정보</span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
          >
            자동 수집 장치의 설치·운영 및 거부에 관한 사항</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >① </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >회사는 이용자의 편의와</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
        >
          서비스 개선을 위해 쿠키를 이용하여 이용자의 접속 기록, 이용 형태 등을
          파악</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >할 수 있지만,</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >이용자는 이를 거부하실 수 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >②</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >이용자는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          브라우저에서 옵션 설정을 통해 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >모든</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          쿠키를 허용 또는 거부하거나, 쿠키가 저장될 때마다 확인을</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
          거치도록 할 수 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >&lt;브라우저 이용자</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">&gt;</span>
      </p>
      <ul type="square" style="margin:0pt; padding-left:0pt">
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.5pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >Chrome : chrome://settings &gt; 고급 &gt; 개인정보 및 보안</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >
          </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >
            &gt; </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >쿠키</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >
            및 기타 사이트 데이터</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >
          </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >&gt; 탐색 트래픽과 함께 ‘추적 안함’ </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >요청 전송 </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >설정</span
          >
        </li>
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.5pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >edge : edge://settings/profiles &gt; </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >개인 정보,</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >
          </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >검색 및 서비스</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >
            &gt; “</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >추적 안 함</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >” </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >요청 보내기 설정</span
          >
        </li>
      </ul>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >&lt;모바일 기기 이용자</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">&gt;</span>
      </p>
      <ul type="square" style="margin:0pt; padding-left:0pt">
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.5pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >Android : 홈 &gt; 설정 &gt; Google설정 &gt; 광고 &gt; 광고맞춤설정
            선택 해제</span
          >
        </li>
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.5pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >IOS 14 이상인 경우: 홈 &gt; 설정 &gt; 개인정보보호 &gt; 추적 &gt;
            앱이 추적을 허용하도록 요청 해제</span
          >
        </li>
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.5pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
            >IOS 14 이하인 경우: 홈 &gt; 설정 &gt; 개인정보보호 &gt; 광고 &gt;
            광고 추적 제한</span
          >
        </li>
      </ul>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 6.15pt; orphans:0; padding-left:16.5pt; page-break-after:avoid; text-align:justify; text-indent:-16.5pt; widows:0"
      >
        <a name="_Hlk95407253"></a
        ><a name="_Toc107041922"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >7. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >개인정보의 </span
          ><span style="-aw-bookmark-end:_Hlk95407253"></span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >파기</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >① </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
          >회사는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
          >개인정보책임자의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
        >
          책임 하에 개인정보 보유기간의 경과, 처리목적 달성</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
          >으로</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
        >
          개인정보가 불필요하게 되었을</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
        >
          때에는 지체없이 해당 개인정보를 파기합니다.
        </span>
      </p>
      <p
        style="margin:0pt; orphans:0; padding-left:16.95pt; text-align:justify; text-indent:-16.95pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
          >②</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-1pt"
          >이용자</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-1pt"
          >로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
          달성되었음에도 불구하고 다른 법령에 따라</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
        >
          개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
          외부와 차단된 별도 DB 또는 테이블에 분리하여 보관합니다.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
          >③</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.9pt"
        >
          개인정보 파기의 절차 및 방법은 다음과 같습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >- 파기절차</span
        >
      </p>
      <ul type="square" style="margin:0pt; padding-left:0pt">
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.5pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
            >수집·이용</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >
            목적이 달성된 개인정보는 지체 없이 파기되며, 휴면회원으로 전환된
            회원의 개인정보는 별도의</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >
          </span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
            >DB에 옮겨져 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에
            따라</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >
            일정기간동안</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >
            안전하게 보관된</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >
            후 지체없이 파기 합니다.</span
          >
        </li>
      </ul>
      <p
        style="margin:0pt 0pt 0pt 11pt; orphans:0; padding-left:10.55pt; text-align:justify; text-indent:-10.55pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >- 파기방법</span
        >
      </p>
      <ul type="square" style="margin:0pt; padding-left:0pt">
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.7pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
            >전자적 파일 형태로 기록·저장된 개인정보 : 기록을 재생할 수 없도록
            파기</span
          >
        </li>
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.7pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
            >종이 문서에 기록·저장된 개인정보 : 분쇄기로 분쇄하거나 소각하여
            파기</span
          >
        </li>
      </ul>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="color:#ff0000; font-family:'맑은 고딕'; font-size:9pt"
          >&#xa0;</span
        >
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 9.9pt; orphans:0; padding-left:16.5pt; page-break-after:avoid; text-align:justify; text-indent:-16.5pt; widows:0"
      >
        <a name="_Toc107041923"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >8. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >개인정보보호를 위한 기술적ㆍ관리적 대책</span
          ></a
        >
      </h3>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">회사는 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >「개인정보보호법」 제29조에 따라 다음과 같이 안전성 확보에 필요한
          기술적, 관리적, 물리적 조치를 하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.7pt; orphans:0; text-align:justify; text-indent:-14pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >①</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >내부관리계획의 수립 및 시행</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.4pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">개인정보</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          내부관리계획 수립 및 시행은 ㈜</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >팡스카이</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >의 내부관리 지침을 준수하여 시행합니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.7pt; orphans:0; text-align:justify; text-indent:-14pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">②</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >개인정보 취급 담당자의 최소화 및 교육</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.4pt; orphans:0; text-align:justify; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >개인정보를</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          취급하는 담당자를 지정하고 최소화하여 개인정보를 관리하는 대책을
          시행하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.7pt; orphans:0; text-align:justify; text-indent:-14pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >③</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >개인정보에 대한 접근 제한</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.4pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >개인정보를</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를
          통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">A</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">CL </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">등</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.7pt; orphans:0; text-align:justify; text-indent:-14pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >④</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >접속기록의 보관 및 위변조 방지</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.4pt; orphans:0; text-align:justify; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >개인정보처리시스템에</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위변조
          및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.7pt; orphans:0; text-align:justify; text-indent:-14pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >⑤</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >개인정보의 암호화</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.4pt; orphans:0; text-align:justify; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >이용자의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          개인정보는 암호화되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는
          저장 및 전송 시</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.7pt; orphans:0; text-align:justify; text-indent:-14pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >⑥</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          해킹 등에 대비한 기술적 대책</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.4pt; orphans:0; text-align:justify; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >㈜</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >팡스카이는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
          해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여
          보안프로그램을</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에
          시스템을 설치하고 기술적/</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의
          통제(Monitoring)는 물론 불법적으로</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          정보를 변경하는 등의 시도를 탐지하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.7pt; orphans:0; text-align:justify; text-indent:-14pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >⑦</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >비인가자에 대한 출입 통제</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.4pt; orphans:0; text-align:justify; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >㈜</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >팡스카이의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          모든 전산 시스템은 전문 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >클라우드</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          서비스를 이용하고 있어 출입을 통제하고 있습니다.</span
        >
      </p>
      <ul type="square" style="margin:0pt; padding-left:0pt">
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.7pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
            >㈜</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
            >팡스카이</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
            >는 이용자 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는
            일들에 대해 책임을 지지 않습니다.</span
          >
        </li>
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.7pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
            >이용자 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID와
            비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.</span
          >
        </li>
        <li
          style="font-family:serif; font-size:10pt; letter-spacing:-0.7pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span
            style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
            >그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의
            분실, 도난, 유출, 위·변조 또는 훼손될 경우 회사는 즉각 이용자에게
            사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</span
          >
        </li>
      </ul>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 9.45pt; orphans:0; padding-left:14.95pt; page-break-after:avoid; text-align:justify; text-indent:-14.95pt; widows:0"
      >
        <a name="_Toc107041924"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >9. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >개인정보보호책임자</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt 0pt 0pt 14.2pt; orphans:0; text-align:justify; text-indent:-14.2pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">①</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >회사는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
        >
          개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
          이용자의 불만처리</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          및 피해구제 등을 위하여</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >개인정보 보호책임자를 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >지정하고</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.7pt; orphans:0; text-align:justify; text-indent:-14pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">②</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >이용자는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련
          문의, 불만처리,</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.4pt"
          >피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실
          수 있습니다. 회사는 이용자의</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          문의에 대해 지체없이 답변 및 처리해드릴 것입니다.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:34.65pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:9pt">▶ </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >개인정보 보호책임자</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:46.75pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">성명</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> : </span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">김재우</span>
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:46.75pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">소속</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> : </span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">정보보호팀</span>
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:46.75pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">문의</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> : </span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">z</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"
          >eus69@panggame.com</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 9.45pt; orphans:0; padding-left:14.95pt; page-break-after:avoid; text-align:justify; text-indent:-14.95pt; widows:0"
      >
        <a name="_Toc107041925"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >10. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >이용자 및 법정대리인의 권리</span
          ><span
            style="font-family:'Malgun Gothic'; font-size:10pt; font-weight:normal"
            >ㆍ</span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >의무 및 그 행사방법에 관한 사항</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt 0pt 0pt 38pt; orphans:0; text-align:justify; text-indent:-18pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >①</span
        ><span style="font:7.0pt 'Times New Roman'"
          >&#xa0;&#xa0;&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >이용자는 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >회사</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >요구</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
        >
          등의 권리를 행사할 수 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 39.05pt; orphans:0; padding-left:13.75pt; text-align:justify; text-indent:-13.75pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.5pt"
          >※</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.5pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.6pt"
          >만 14세 미만 아동에 관한 개인정보의 열람</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.6pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.6pt"
          >등 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.6pt"
          >미성년자인</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.5pt"
        >
          정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를
          행사하거나 법정대리인을 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.5pt"
          >통하여</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:9pt; letter-spacing:-0.5pt"
        >
          권리를 행사할 수도 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 38pt; orphans:0; text-align:justify; text-indent:-18pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >②</span
        ><span style="font:7.0pt 'Times New Roman'"
          >&#xa0;&#xa0;&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >이용자 혹은 만 14세 미만 아동의 개인정보 조회 및 수정을 위해서는
          마이페이지의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >‘</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >내 정보</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >’, ‘회원탈퇴’ 메뉴를 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >이용해서 직접 열람, 정정 또는 탈퇴가 가능합니다. 또는
          개인정보보호책임자에게 서면 또는 이메일로 연락하시면 지체없이
          조치하도록 하겠습니다</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.8pt"
          >.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 38pt; orphans:0; text-align:justify; text-indent:-18pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >③</span
        ><span style="font:7.0pt 'Times New Roman'"
          >&#xa0;&#xa0;&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.8pt"
          >권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을
          통하여 하실 수도 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.8pt"
          >있습니다</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.8pt"
          >. 이 경우</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
          “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >따른</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
          위임장을 제출하셔야 합니다.
        </span>
      </p>
      <p
        style="margin:0pt 0pt 0pt 38pt; orphans:0; text-align:justify; text-indent:-18pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >④</span
        ><span style="font:7.0pt 'Times New Roman'"
          >&#xa0;&#xa0;&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
          제37조 제2항에 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >의하여</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >이용자</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.7pt"
          >의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
          권리가 제한 될 수 있습니다.
        </span>
      </p>
      <p
        style="margin:0pt 0pt 0pt 38pt; orphans:0; text-align:justify; text-indent:-18pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >⑤</span
        ><span style="font:7.0pt 'Times New Roman'"
          >&#xa0;&#xa0;&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
          대상으로 명시되어 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >있는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          경우에는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
          그 삭제를 요구할 수 없습니다.
        </span>
      </p>
      <p
        style="margin:0pt 0pt 0pt 38pt; orphans:0; text-align:justify; text-indent:-18pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >⑥</span
        ><span style="font:7.0pt 'Times New Roman'"
          >&#xa0;&#xa0;&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.65pt"
          >회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된
          개인정보는 ‘보유기간’에</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
          명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
          처리하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 38pt; orphans:0; text-align:justify; text-indent:-18pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">⑦</span
        ><span style="font:7.0pt 'Times New Roman'"
          >&#xa0;&#xa0;&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.8pt"
          >회사는 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.8pt"
          >정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.8pt"
          >정지의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.8pt"
        >
          요구 시 열람 등 요구를 한 자가</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
        >
          본인이거나 정당한 대리인인지를 확인합니다.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 9.45pt; orphans:0; padding-left:14.95pt; page-break-after:avoid; text-align:justify; text-indent:-14.95pt; widows:0"
      >
        <a name="_Toc107041926"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >11. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >권익침해 구제방법</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt 0pt 0pt 14.2pt; orphans:0; text-align:justify; text-indent:-14.2pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">①</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >정보주체는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >한국인터넷진흥원</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >수</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의
          기관에 </span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">문의하시기</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> 바랍니다</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">.</span>
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:23.45pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt"
          >1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972
          (www.kopico.go.kr)</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:23.45pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt"
          >2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:23.45pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt"
          >3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:23.45pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt"
          >4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)
        </span>
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.2pt; orphans:0; text-align:justify; text-indent:-14.2pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >②</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >회사</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >는 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >이용자</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >의 개인정보자기결정권을 보장하고, 개인정보</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >침해로</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          인한 상담 및 피해 구제를 위해 노력하고 있으며, 신고나 상담이 필요한
          경우 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >아래의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          담당부서로 연락해 주시기 바랍니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:23.45pt; widows:0"
      >
        <span style="font-family:'MS Gothic'; font-size:10pt">▸</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> </span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"
          >개인정보보호 관련 고객 상담 및 신고</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:38.95pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">부서명</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> : </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >서비스운영팀</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:38.95pt; widows:0"
      >
        <span style="font-family:'맑은 고딕'; font-size:10pt">연락처</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt"> : </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.5pt"
          >070-4658-9412</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.2pt; orphans:0; text-align:justify; text-indent:-14.2pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >③</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >「개인정보 보호법」 제35조(개인정보의 열람), 제36조(개인정보의
          정정·삭제), </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >제</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >회사가</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          행한 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >처분</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이
          정하는 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
          >바에</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.6pt"
        >
          따라 행정심판을 청구할 수 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:23.45pt; widows:0"
      >
        <span style="font-family:'MS Gothic'; font-size:10pt">▸</span
        ><span style="font-family:'맑은 고딕'; font-size:10pt">
          중앙행정심판위원회 : (국번없이) 110 (www.simpan.go.kr)</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="color:#474747; font-family:'맑은 고딕'; font-size:9pt"
          >&#xa0;</span
        >
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 9.45pt; orphans:0; padding-left:14.95pt; page-break-after:avoid; text-align:justify; text-indent:-14.95pt; widows:0"
      >
        <a name="_Toc107041927"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >12. 영상정보처리기기 설치·운영</span
          ></a
        >
      </h3>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">①</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">회사는</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          아래와 같이 영상정보처리기기를 설치·운영하고 있습니다.
        </span>
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >1. 영상정보처리기기 설치근거·목적 : ㈜</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >팡스카이</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >의 시설안전, 화재예방</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.6pt; orphans:0; padding-left:14.4pt; text-align:justify; text-indent:-14.4pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >2. 설치 대수, 설치 위치, 촬영 범위 :
        </span>
      </p>
      <table
        cellspacing="0"
        cellpadding="0"
        style="border-collapse:collapse; margin-left:21.05pt"
      >
        <tr style="height:13.85pt">
          <td
            style="background-color:#d8d8d8; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:102.6pt"
          >
            <p
              style="margin:0pt 0pt 9pt; orphans:0; text-align:center; widows:0"
            >
              <span
                style="font-family:'맑은 고딕'; font-size:9pt; font-weight:bold"
                >설치 대수</span
              >
            </p>
          </td>
          <td
            style="background-color:#d8d8d8; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:336.15pt"
          >
            <p
              style="margin:0pt 0pt 9pt; orphans:0; text-align:center; widows:0"
            >
              <span
                style="font-family:'맑은 고딕'; font-size:9pt; font-weight:bold"
                >설치 위치 및 촬영 범위</span
              >
            </p>
          </td>
        </tr>
        <tr style="height:16.5pt">
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:102.6pt"
          >
            <p
              style="margin:0pt 0pt 9pt; orphans:0; text-align:center; widows:0"
            >
              <span style="font-family:'맑은 고딕'; font-size:9pt">8</span
              ><span style="font-family:'맑은 고딕'; font-size:9pt">대</span>
            </p>
          </td>
          <td
            style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:336.15pt"
          >
            <ul type="square" style="margin:0pt; padding-left:0pt">
              <li
                style="font-family:serif; font-size:9pt; line-height:15.35pt; margin:0pt 0pt 0pt 11.45pt; orphans:0; text-indent:0pt; widows:0"
              >
                <span
                  style="background-color:#ffffff; font-family:'Malgun Gothic'; font-size:9pt"
                  >출입문(주차장) 1대 내측에서 도로 방향</span
                >
              </li>
              <li
                style="font-family:serif; font-size:9pt; line-height:15.35pt; margin:0pt 0pt 0pt 11.45pt; orphans:0; text-indent:0pt; widows:0"
              >
                <span style="font-family:'맑은 고딕'; font-size:9pt"
                  >지하1층(1대)</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"> </span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"
                  >출입구에서 내측 방향</span
                >
              </li>
              <li
                style="font-family:serif; font-size:9pt; line-height:15.35pt; margin:0pt 0pt 0pt 11.45pt; orphans:0; text-indent:0pt; widows:0"
              >
                <span style="font-family:'맑은 고딕'; font-size:9pt"
                  >지하2층(</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">1</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">대)</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"> </span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"
                  >내측에서 출입구 방향</span
                >
              </li>
              <li
                style="font-family:serif; font-size:9pt; line-height:15.35pt; margin:0pt 0pt 0pt 11.45pt; orphans:0; text-indent:0pt; widows:0"
              >
                <span style="font-family:'맑은 고딕'; font-size:9pt">2층(</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">1</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">대)</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"> </span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"
                  >내측에서 출입구 방향</span
                >
              </li>
              <li
                style="font-family:serif; font-size:9pt; line-height:15.35pt; margin:0pt 0pt 0pt 11.45pt; orphans:0; text-indent:0pt; widows:0"
              >
                <span style="font-family:'맑은 고딕'; font-size:9pt">3층(</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">2</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">대)</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"> </span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"
                  >내측에서 출입구 방향,</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"> </span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"
                  >출입구에서 내측 방향</span
                >
              </li>
              <li
                style="font-family:serif; font-size:9pt; line-height:15.35pt; margin:0pt 0pt 0pt 11.45pt; orphans:0; text-indent:0pt; widows:0"
              >
                <span style="font-family:'맑은 고딕'; font-size:9pt">4층(</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">1</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">대)</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"> </span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"
                  >내측에서 출입구 방향</span
                >
              </li>
              <li
                style="font-family:serif; font-size:9pt; line-height:15.35pt; margin:0pt 0pt 0pt 11.45pt; orphans:0; text-indent:0pt; widows:0"
              >
                <span style="font-family:'맑은 고딕'; font-size:9pt">옥상(</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">1</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt">대)</span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"> </span
                ><span style="font-family:'맑은 고딕'; font-size:9pt"
                  >출입구에서 내측 방향</span
                >
              </li>
            </ul>
          </td>
        </tr>
      </table>

      <p
        style="margin:0pt 0pt 0pt 15.6pt; orphans:0; padding-left:14.4pt; text-align:justify; text-indent:-14.4pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >3. 관리책임자, 담당부서 및 영상정보에 대한 접근권한자</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">- </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >김재우 개인영상정보 관리책임자 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">/ </span
        ><span style="font-family:'맑은 고딕'; font-size:9pt">0</span
        ><span style="font-family:'맑은 고딕'; font-size:9pt"
          >70-4658-9405</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >4. 영상정보 촬영시간, 보관기간, 보관장소, 처리방법
        </span>
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:31.15pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >- 촬영시간 : 24시간 촬영</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:31.15pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >- 보관기간 : 촬영 시부터</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> 최대</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> 90일 </span>
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:31.15pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >- 보관장소</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">: </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >본사(지정된 통제구역)</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 31.15pt; orphans:0; padding-left:68.95pt; text-align:justify; text-indent:-68.95pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">-</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >처리방법 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">: </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >개인영상정보의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          목적</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
        </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >외 이용, 제3자 제공, 파기, 열람 등 요구에 관한 사항을 기록</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          ·</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
        </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >관리하고, 보관기간 만료</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >시 복원이 불가능한 방법으로 영구 삭제(출력물의 경우 파쇄 또는
          소각)합니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.6pt; orphans:0; padding-left:14.4pt; text-align:justify; text-indent:-14.4pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >5. 영상정보 확인 방법 및 장소 : </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >개인영상정보 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >관리책임자에게 요구 / ㈜</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >팡스카이 본사</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.6pt; orphans:0; padding-left:14.4pt; text-align:justify; text-indent:-14.4pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">6. </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
          >정보주체의 영상정보 열람 등 요구에 대한 조치 : 개인영상정보 열람·존재
          확인 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
          >청구서로</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.3pt"
        >
          신청하여야</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"> </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >하며, 정보주체 자신이 촬영된 경우 또는 명백히 정보주체의 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >생명·신체·재산의</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          이익을 위해 필요한</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          경우에 한해 열람을 허용함
        </span>
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.6pt; orphans:0; padding-left:14.4pt; text-align:justify; text-indent:-14.4pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">7. </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >영상정보 보호를 위한 기술적·관리적·물리적 조치 : 내부관리계획 수립,
          접근통제 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >및</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          접근권한 제한,</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          영상정보의 안전한 저장·전송기술 적용, 처리기록 보관 및</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          위·변조</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          방지조치, 보관시설 마련 및 잠금장치 설치
        </span>
      </p>
      <p
        style="margin:0pt 0pt 0pt 15.6pt; orphans:0; padding-left:14.4pt; text-align:justify; text-indent:-14.4pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >8. 영상정보처리기기 운영·관리 방침의 변경에 관한 사항</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 30.05pt; orphans:0; text-align:justify; text-indent:0.75pt; widows:0"
      >
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >이</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
        >
          영상정보처리기기 운영·관리 방침은 2022년 3월 </span
        ><span
          style="color:#ff0000; font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >0</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >일에 제정되었으며, </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >법령·정책</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
        >
          또는 보안기술의</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          변경에 따라 내용의 추가·삭제 및 수정이 있을 시에는 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">지체</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          없이 본 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">회사</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          홈페이지를 통해 변경사유 및 내용을 공지하도록 하겠습니다.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 5.95pt; orphans:0; padding-left:14.95pt; page-break-after:avoid; text-align:justify; text-indent:-14.95pt; widows:0"
      >
        <a name="_Toc107041928"
          ><span
            style="font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >1</span
          ><span
            style="font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >3. </span
          ><span
            style="font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >청소년보호를 위한 활동</span
          ></a
        >
      </h3>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >회사는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
        >
          각종 청소년 유해정보로부터 청소년을 보호하고자 관련법에 따라 만
          19세미만의 청소년이 유해정보에 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >접근할 수 없도록 청소년보호정책을 마련하여 시행하고 있습니다. 또
          회사는 청소년의 건전한 </span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.1pt"
          >성장을 저해하는 음란, 불법 유해정보와 비윤리적, 반사회적 행위에
          대해서는 엄격하게 제재하기 위하여</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          다음과 같이 활동하고 있습니다.</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >1. 청소년유해정보로부터의 청소년보호계획의 수립</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >2. 청소년유해정보에 대한 청소년 접근제한 및 관리조치</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >3. 정보통신업무 종사자에 대한 청소년유해정보로부터의 청소년보호를
          위한 교육</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >4. 청소년유해정보로 인한 피해상담 및 고충처리</span
        >
      </p>
      <p
        style="margin:0pt; orphans:0; text-align:justify; text-indent:15.6pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt"
          >5. 그 밖에 청소년유해정보로부터 청소년을 보호하기 위하여 필요한
          사항</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'맑은 고딕'; font-size:9pt">&#xa0;</span>
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 9.45pt; orphans:0; padding-left:14.95pt; page-break-after:avoid; text-align:justify; text-indent:-14.95pt; widows:0"
      >
        <a name="_Toc107041929"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >14. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >기타</span
          ></a
        >
      </h3>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
          >회사는</span
        ><span
          style="font-family:'Malgun Gothic'; font-size:10pt; letter-spacing:-0.2pt"
        >
          다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이
          경우 회사는 외부사이트</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나
          자료의 유용성, 진실성, 적법성에 대해 책임질 수 없으며 보증할 수
          없습니다.
        </span>
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">회사가</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt">
          포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈
          경우 해당 사이트의 개인정보 처리방침은 회사와 무관하므로 새로 방문한
          사이트의 정책을 확인하시기 바랍니다.</span
        >
      </p>
      <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
        <span style="font-family:'Malgun Gothic'; font-size:10pt">&#xa0;</span>
      </p>
      <h3
        style="font-weight:normal; margin:0pt 0pt 0pt 9.45pt; orphans:0; padding-left:14.95pt; page-break-after:avoid; text-align:justify; text-indent:-14.95pt; widows:0"
      >
        <a name="_Toc107041930"
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >15. </span
          ><span
            style="color:#474747; font-family:'맑은 고딕'; font-size:11pt; font-weight:bold"
            >개인정보 처리방침 변경</span
          ></a
        >
      </h3>
      <p
        style="margin:0pt 0pt 0pt 14.2pt; orphans:0; text-align:justify; text-indent:-14.2pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">①</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >이 개인정보 처리방침은 </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >2022.07.14</span
        ><span
          style="color:#ff0000; font-family:'Malgun Gothic'; font-size:10pt"
          >.</span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >부터 적용됩니다.</span
        >
      </p>
      <p
        style="margin:0pt 0pt 0pt 14.2pt; orphans:0; text-align:justify; text-indent:-14.2pt; widows:0"
      >
        <span style="font-family:'Malgun Gothic'; font-size:10pt">②</span
        ><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span
        ><span style="font-family:'Malgun Gothic'; font-size:10pt"
          >이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.</span
        >
      </p>
      <ul type="square" style="margin:0pt; padding-left:0pt">
        <li
          style="font-family:serif; font-size:10pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span style="font-family:'Malgun Gothic'; font-size:10pt">2016</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">.</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">05</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">.</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">09</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">.</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">
            ~ 2017.12.06. </span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">적용 </span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">(</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt"
            >클릭)</span
          >
        </li>
        <li
          style="font-family:serif; font-size:10pt; margin:0pt 0pt 0pt 28.35pt; orphans:0; text-align:justify; text-indent:0pt; widows:0"
        >
          <span style="font-family:'Malgun Gothic'; font-size:10pt">2</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">017</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">.</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">12</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">.</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt"
            >07. ~ 2022.07.14. </span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">적용 </span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt">(</span
          ><span style="font-family:'Malgun Gothic'; font-size:10pt"
            >클릭)</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Privacykr",
};
</script>

<style lang="scss" scoped>
#privacy-kr {
  max-width: 100%;
  padding: 15px;
  margin: 80px auto;
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
}
</style>
