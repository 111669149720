<template>
  <div v-if="$i18n.locale!='ko'" class="container privacy">
    <div class="privacy-title">
      {{ $t('개인정보처리방침') }}
    </div>
    <div class="privacy-content">
      <div class="content-item">
        <p class="item">{{ $t('제 1 조 총칙') }}</p>
        <p>{{ $t('1. ㈜팡스카이(이하 ‘회사’라 합니다.)는 서비스를 제공하기 위해 필요한 필수항목을 이용자의 동의 하에 수집하고 있습니다. 개인정보란 생존하는 개인에 관한 정보로서 해당 정보에 포함되어 있는 성명 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보를 말합니다.') }}</p>
        <p>{{ $t('2. 회사는 이용자의 개인정보를 소중히 처리하며, 통신비밀보호법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 방송통신위원회가 제정한 『개인정보보호지침』을 준수하고 있습니다.') }}</p>
        <p>{{ $t('3. 회사는 관련 법령에 의거하여 개인정보처리방침을 통해 이용자의 권익 보호를 위해 최선을 다하고 있습니다.') }}</p>
        <p>{{ $t('4. 회사는 개인정보처리방침을 홈페이지 첫 화면 하단에 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다.') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 2 조 수집하는 개인정보 항목 및 수집방법') }}</p>
        <p>{{ $t('1. 회사는 회원가입 시 서비스 제공을 위해 필요한 최소한의 개인정보만을 수집합니다.') }}</p>
        <p>{{ $t('2. 이용자가 회사의 서비스를 이용하기 위해서는 회원가입 시 개인정보를 입력하셔야 하며, 회사는 필수입력항목과 선택입력항목으로 구분하여 개인정보를 수집하고 있습니다. 필수입력항목은 회원가입을 위해 반드시 제공해야 하는 개인정보이며, 선택입력항목은 입력하지 않으셔도 회원가입이 가능한 개인정보를 의미합니다. 필수항목과 선택항목은 다음과 같습니다.') }}</p>
        <p>{{ $t('[일반회원]') }}</p>
        <p>{{ $t('- 필수항목 : 이름, 아이디(ID), 비밀번호, 만 14세 미만인 경우 법정대리인 정보, 전화번호, 휴대전화번호, 이용자정보 확인 질문/답변 주로 쓰는 이메일, 네이버, 페이스북, 구글 등의 SNS 계정 정보, CI, DI') }}</p>
        <p>{{ $t('3. 수집방법') }}</p>
        <p>{{ $t('홈페이지(이용자가입), 서면양식, 전화, 기타방법') }}</p>
        <p>{{ $t('4. 회사는 설문조사, 경품발송 등을 위한 목적으로 이용자의 개인정보 기재를 요청하거나 보다 나은 서비스를 위하여 소프트웨어 정보를 추출할 수 있으며, 수집한 목적 등이 완료된 경우에는 관계법령에서 정한 경우를 제외하고 수집된 개인정보가 열람 또는 이용될 수 없도록 처리합니다.') }}</p>
        <p>{{ $t('5. 유료서비스를 이용하고자 하는 이용자는 본인의 이용료결제에 필요한 범위내에서 은행계좌정보, 신용카드정보 등의 항목을 입력해야 하며 이는 이용료 결제를 위한 목적으로 사용됩니다.') }}</p>
        <p>{{ $t('6. 회사는 서비스의 불량이용자 규제를 위해 IP주소, 쿠키정보, 불량이용기록 등을 수집할 수 있습니다.') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 3 조 개인정보 수집 및 이용에 대한 동의') }}</p>
        <p>{{ $t('회사는 이용자의 개인정보 수집 및 이용에 대한 동의를 받고 있습니다. 이용자의 개인정보 수집과 관련하여 회원가입 시 개인정보처리방침에 대한 동의절차를 마련하여, 동의 버튼을 클릭하면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다. 다만, 아래의 경우에는 개인정보 수집 및 이용에 대한 이용자의 사전 동의를 구하지 않을 수 있습니다.') }}</p>
        <p>{{ $t('1. 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적 기술적인사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우') }}</p>
        <p>{{ $t('2. 정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우') }}</p>
        <p>{{ $t('3. 다른 법률에 특별한 규정이 있는 경우') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 4 조 개인정보의 수집 및 이용목적') }}</p>
        <p>{{ $t('1. 회원 가입 시 수집하는 개인정보 별 수집목적은 다음과 같습니다.') }}</p>
        <p class="text-indent">{{ $t('(1) 이름(실명), 아이디, 비밀번호: 신용평가기관을 통한 실명확인, 중복가입확인, 서비스 이용에 따른 본인식별, 연령제한 서비스, 인구통계학적 분석, 고객센터의 운영, 불량이용자의 부정한 이용재발 방지') }}</p>
        <p class="text-indent">{{ $t('(2) 비밀번호 힌트/답: 이용자가 비밀번호를 분실한 경우 이에 고객지원을 위한 본인확인용 질문/답') }}</p>
        <p class="text-indent">{{ $t('(3) 전자우편: 공지사항 전달, 본인 의사확인, 고객문의 답변 등 원활한 의사소통 채널확보, 신규 서비스 나 이벤트 정보 등 최신 정보 등의 안내') }}</p>
        <p class="text-indent">{{ $t('(4) 거주지 주소: 지역별 이벤트 등의 정보 제공, 지역별 커뮤니티 서비스 제공') }}</p>
        <p class="text-indent">{{ $t('(5) 연락처(전화번호/휴대폰): 비밀번호 분실 시 SMS 통보 및 고객지원을 위한 연락처 확보') }}</p>
        <p class="text-indent">{{ $t('(6) 부모님(법정대리인) 성명 14세 미만 이용자(이하 “아동”이라 합니다)의 개인정보 수집시 법정대리인 동의를 얻기 위한 실명 확인 및 법정 대리인의 아동 개인정보 열람 등을 위한 고객지원, 만18세 미만 청소년의 가입시 법정대리인 동의를 얻기 위한 실명 확인 및 게임물 이용내역의 고지') }}</p>
        <p class="text-indent">{{ $t('(7) 휴대폰 소유정보, 신용카드 소유정보, 본인확인 서류: 가입 시 혹은 기타 개인정보 변경 요청 시 본인확인을 위한 인증수단') }}</p>
        <p>{{ $t('2. 일부 유료 서비스 이용 시 유료 결제를 위해 수집하는 결제정보 별 수집목적은 다음과 같습니다.') }}</p>
        <p>{{ $t('(1) 은행계좌정보, 신용카드정보, 핸드폰번호, 전화번호: 유료정보이용에 대한 과금') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 5 조 조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항') }}</p>
        <p>{{ $t('■ 쿠키란?') }}</p>
        <p class="text-indent">{{ $t('특정 웹사이트에서 웹서버가 웹브라우저에게 전달하는 작은 텍스트 파일 서비스 개선을 위해 쿠키를 이용하여 이용자의 접속 기록, 이용 형태 등을 파악합니다.') }}</p>
        <p class="text-indent">{{ $t('이용자는 브라우저에서 옵션 설정을 통해 쿠키가 수집되지 않도록 거부할 수 있습니다.') }}</p>
        <p class="text-indent">{{ $t('- Internet Explorer: 도구 > 인터넷 옵션 > 개인정보 > 고급') }}</p>
        <p class="text-indent">{{ $t('- Chrome: 설정 > 설정 표시 > 개인정보의 콘텐츠 설정 버튼 > 쿠키 그 외 브라우저의 경우 브라우저 별 설정 방식에 따릅니다.') }}</p>
        <p>{{ $t('■ 웹로그 분석이란?') }}</p>
        <p class="text-indent">{{ $t('웹사이트상에서 이용자의 서비스 이용 형태에 대한 분석') }}</p>
        <p class="text-indent">{{ $t('oogle Analytics와 같이 다양한 외부 웹로그분석 도구를 사용할 수 있으며 Google Analytics의 경우 데이터가 사용되는 것을 거부할 수 있습니다.') }}</p>
        <p class="text-indent">{{ $t('- Google Analytics 차단: https://tools.google.com/dlpage/gaoptout/ 그 외 웹로그분석 도구의 경우 도구 별 거부 방식에 따릅니다.') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 6 조 개인정보의 제공') }}</p>
        <p>{{ $t('1. 회사는 이용자의 동의가 있거나 법령의 규정에 의한 경우를 제외하고는 어떠한 경우에도 [개인정보의 수집목적 및 이용목적]에서 고지한 범위를 넘어 이용자의 개인정보를 이용하거나 타인 또는 타기업/기관에 제공하지 않습니다.') }}</p>
        <p>{{ $t('2. 이용자의 개인정보를 제공하는 경우에는 개인정보를 제공받는 자, 개인정보를 제공받는 자의 개인정보 이용 목적, 제공하는 개인정보 항목, 개인정보를 제공받는 자의 개인정보 보유 및 이용기간에 대해 개별적으로 인터넷사이트, 전자우편, 서면, 전화 등을 통해 고지한 후 이에 대한 사전 동의를 구합니다.다만, 다음의 경우에는 이용자의 동의 없이 개인정보를 제공할 수 있습니다.') }}</p>
        <p class="text-indent">{{ $t('(1) 서비스 제공에 따른 요금 정산을 위하여 필요한 경우') }}</p>
        <p class="text-indent">{{ $t('(2) 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우') }}</p>
        <p class="text-indent">{{ $t('(3) 법령에 특별한 규정이 있는 경우') }}</p>
        <p>{{ $t('3. 회사는 관계 법령에 의하거나 수사기관의 요청에 의해 이용자의 개인정보를 제공한 경우 이를 당사자에게 고지하는 것을 원칙으로 합니다. 단, 법률상의 근거에 의해 부득이하게 고지를 못할 수도 있습니다.') }}</p>
        <p>{{ $t('4. 회사는 아래와 같은 경우 이용자의 동의 하에 개인정보를 제3자에게 제공할 수 있습니다.') }}</p>
        <p class="text-indent">{{ $t('(1) 물품구매, 유료컨텐츠 이용 등의 배송 및 정산을 위해 이용자의 이름, 주소, 전화번호 등이 해당 쇼핑몰 업체, 유료컨텐츠 제공자, 배송업자에게 제공될 수 있습니다.') }}</p>
        <p class="text-indent">{{ $t('(2) 각종 이벤트 행사에 참여한 회원의 개인정보가 해당 이벤트의 주최자에게 제공될 수 있습니다.') }}</p>
        <p>{{ $t('5. 회사는 이용자에게 제공되는 서비스의 질을 향상시키기 위해 맞춤 서비스, 온라인광고 서비스, 쇼핑몰 서비스, 커뮤니티 서비스, 유료컨텐츠 서비스, 모바일 서비스, 보험ㆍ신용카드 등의 텔레마케팅 서비스, 통계작성 또는 시장조사 등 다양한 서비스를 제공할 목적으로 여러 분야의 전문 컨텐츠 사업자 혹은 비즈니스 사업자와 함께 파트너쉽을 맺을 수 있습니다.') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 7 조 개인정보의 처리위탁') }}</p>
        <p>{{ $t('1. 회사는 제3자에게 이용자의 개인정보를 수집ㆍ보관ㆍ처리ㆍ이용ㆍ제공ㆍ관리ㆍ감독 및 교육ㆍ파기 등(이하 "처리"라 한다)을 할 수 있도록 업무를 위탁(이하 "개인정보 처리위탁"이라 한다)하는 경우에는 다음 각 호의 사항 모두를 이용자에게 알리고 동의를 받습니다.') }}</p>
        <p class="text-indent">{{ $t('(1) 개인정보 처리위탁을 받는 자(이하 "수탁자"라 한다)') }}</p>
        <p class="text-indent">{{ $t('(2) 개인정보 처리위탁을 하는 업무의 내용') }}</p>
        <p>{{ $t('2. 회사는 이용자에게 다양하고 높은 수준의 서비스 제공을 위해 반드시 필요한 업무에 대해서는 아래와 같이 외부전문업체에 위탁하여 운영합니다.') }}</p>
        <p>
          <table border="1">
            <tr>
              <th>{{ $t('수탁업체') }}</th>
              <th>{{ $t('위탁업무내용') }}</th>
              <th>{{ $t('개인정보 이용기간') }}</th>
            </tr>
            <tr>
              <td>{{ $t('나이스신용평가정보㈜') }}</td>
              <td>{{ $t('이용자 본인 확인') }}</td>
              <td>{{ $t('별도 저장하지 않음') }}</td>
            </tr>
            <tr>
              <td>{{ $t('KG이니시스') }}</td>
              <td>{{ $t('유료서비스 결제처리 및 요금정산') }}</td>
              <td>{{ $t('결제일 기준 5년') }}</td>
            </tr>
          </table>
        </p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 8 조 이용자 및 법정대리인의 권리와 그 행사 방법') }}</p>
        <p>{{ $t('1. 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수 있습니다.') }}</p>
        <p>{{ $t('2. 이용자 혹은 만 14세 미만 아동의 개인정보 조회 및 수정을 위해서는 마이페이지의 ‘개인정보관리’를 가입해지를 위해서는 ‘회원탈퇴’ 메뉴를 이용해서 직접 열람, 정정 또는 탈퇴가 가능합니다. 또는 개인정보보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하도록 하겠습니다.') }}</p>
        <p>{{ $t('3. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리 결과를 제 3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.') }}</p>
        <p>{{ $t('4. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 ‘개인정보의 보유기간 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 9 조 개인정보의 보유기간 및 이용기간') }}</p>
        <p>{{ $t('1. 회사는 이용자가 이용자자격을 유지하고 있는 동안에는 이용자가 회사에 제공한 개인정보를 계속 보유합니다.') }}</p>
        <p>{{ $t('2. 개인정보제공에 대해 동의철회(회원탈퇴)할 경우 즉시 수집된 개인정보를 즉시 파기하며, 예외 규정을 제외하고 어떠한 목적으로도 사용할 수 없도록 합니다.') }}</p>
        <p>{{ $t('3. 일시적인 목적에 의해 수집한 개인정보는 그 이용목적을 달성하면 지체 없이 파기합니다. 단, 이벤트응모를 위해 주소 등의 개인정보를 제공한 경우에는 이벤트상품 발송 및 이벤트응모 고객에 대한 고객응대를 위해 해당 이벤트의 종료시점으로부터 6개월간 해당 개인정보를 보유합니다.') }}</p>
        <p>{{ $t('4. 이용자가 본인이 회사의 이용자임을 증빙하기 위해 회사에 발송한 신분증 사본 등 서류일체는 본인확인 후 즉시 파기합니다.') }}</p>
        <p>{{ $t('5. 법정대리인의 동의여부를 증빙하는 자료는 접수일로부터 최소 2년간 보유함을 원칙으로 하나, 법정대리인 동의 이후 2년 이내에 이용자가 회원탈퇴를 하는 경우에는 그 즉시 파기합니다.') }}</p>
        <p>{{ $t('6. 『상법』 및 『전자상거래 등에서의 소비자보호에 관한 법률』등 관련법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 법령에서 정한 일정한 기간 동안 이용자정보를 보관합니다. 이 때 회사는 보관하는 정보를 그 보관 이외의 목적으로 사용할 수 없습니다. 구체적인 사항은 다음과 같습니다.') }}</p>
        <p class="text-indent">{{ $t('(1) 계약 또는 청약철회 등에 관한 기록') }}</p>
        <p class="text-indent">{{ $t('보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률') }}</p>
        <p class="text-indent">{{ $t('보존 기간 : 5년') }}</p>
        <p class="text-indent">{{ $t('(2) 대금결제 및 재화 등의 공급에 관한 기록') }}</p>
        <p class="text-indent">{{ $t('보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률') }}</p>
        <p class="text-indent">{{ $t('보존 기간 : 5년') }}</p>
        <p class="text-indent">{{ $t('(3) 소비자의 불만 또는 분쟁처리에 관한 기록') }}</p>
        <p class="text-indent">{{ $t('보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률') }}</p>
        <p class="text-indent">{{ $t('보존 기간 : 3년') }}</p>
        <p class="text-indent">{{ $t('(4) 전자금융 거래에 관한 기록') }}</p>
        <p class="text-indent">{{ $t('보존 이유 : 전자금융거래법') }}</p>
        <p class="text-indent">{{ $t('보존 기간 : 3년') }}</p>
        <p class="text-indent">{{ $t('(5) 표시/광고에 관한 기록') }}</p>
        <p class="text-indent">{{ $t('보존 이유 : 전자상거래 등에서의 소비자보호에 관한 법률') }}</p>
        <p class="text-indent">{{ $t('보존 기간 : 6개월') }}</p>
        <p class="text-indent">{{ $t('(6) 본인 확인에 관한 기록') }}</p>
        <p class="text-indent">{{ $t('보존 이유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률') }}</p>
        <p class="text-indent">{{ $t('보존 기간 : 6개월') }}</p>
        <p class="text-indent">{{ $t('(7)서비스 이용 관련 개인 정보(접속기록, 이용기록, IP정보)') }}</p>
        <p class="text-indent">{{ $t('보존 이유 : 통신비밀보호법') }}</p>
        <p class="text-indent">{{ $t('보존 기간 : 3개월') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 10 조 개인정보 파기절차 및 방법') }}</p>
        <p>{{ $t('회사는 이용자에게 사전에 고지하고 동의 받은 개인정보의 수집ㆍ이용목적이 달성되면 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.') }}</p>
        <p>{{ $t('1. 파기절차') }}</p>
        <p class="text-indent">{{ $t('(1) 이용자가 입력한 개인정보는 수집ㆍ이용목적이 달성되면 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라') }}</p>
        <p class="text-indent">{{ $t('(2) 제10조에 근거하여 일정기간 저장 후, 재생할 수 없는 방법으로 파기됩니다.') }}</p>
        <p class="text-indent">{{ $t('(3) 별도 DB로 옮겨진 이용자의 개인정보는 법령에 의한 경우가 아닌 다른 목적으로 이용되지 않습니다.') }}</p>
        <p>{{ $t('2. 파기방법') }}</p>
        <p class="text-indent">{{ $t('(1) 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.') }}</p>
        <p class="text-indent">{{ $t('(2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 11 조 개인정보보호를 위한 기술적ㆍ관리적 대책') }}</p>
        <p>{{ $t('1. 이용자의 개인정보는 이용자 자신이 설정한 비밀번호에 의해 우선적으로 보호되고 있습니다. 따라서 이용자는 절대로 비밀번호를 타인에게 알려주거나 공유해선 안되며, 회사 서비스의 이용완료 후에는 반드시 로그아웃(LOG-OUT)을 이용하여 웹 브라우저를 종료하여야 합니다. 특히 다른 사람과 컴퓨터를 공유하여 사용하거나 공공장소에서 이용한 경우에는 타인에게 개인정보가 알려지는 것을 막기 위하여 위 절차는 반드시 필요합니다. 이외에도 회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 및 훼손되지 아니하도록 안전성 확보를 위하여 다음과 같은 기술적 내지 관리적 대책을 강구하고 있습니다.') }}</p>
        <p>{{ $t('2. 기술적 대책') }}</p>
        <p class="text-indent">{{ $t('(1) 이용자의 비밀번호는 암호화 처리되어 이용자 개개인의 비밀번호를 알 수 없도록 되어 있습니다.') }}</p>
        <p class="text-indent">{{ $t('(2) 개인정보의 훼손에 대비하여 정보를 수시로 백업하고 있으며 최신의 백신프로그램을 이용하여 이용자의 개인정보와 자료가 누출되거나 손상되지 않도록 하고 있습니다.') }}</p>
        <p class="text-indent">{{ $t('(3) 암호화 통신 등을 통하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.') }}</p>
        <p class="text-indent">{{ $t('(4) 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며 기타 시스템적으로 보완성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.') }}</p>
        <p>{{ $t('3. 관리적 대책') }}</p>
        <p class="text-indent">{{ $t('(1) 개인정보보호책임자와 최소한으로 구성된 인원들에 대해서만 개인정보 관련 처리를 한정시키고 있으며 기타 인원에 대해서는 개인정보에 대한 접근권한을 제한하고 있습니다.') }}</p>
        <p class="text-indent">{{ $t('(2) 개인정보처리인원에 대한 교육을 통하여 개인정보보호의무 등 관련된 의무를 항상 강조하고 있으며 개인정보처리방침이행사항 및 담당자의 준수 여부를 수시로 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 최선의 노력을 기울이고 있습니다.') }}</p>
        <p>{{ $t('4. 회사는 이용자 스스로의 실수 또는 부주의 기타 기본적인 인터넷의 위험으로 인해 발생되는 개인정보유출 및 훼손에 대해서는 어떠한 책임도 부담하지 않습니다.') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 12 조 개인정보보호책임자 및 담당자의 연락처') }}</p>
        <p>{{ $t('이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 회사는 개인정보보호책임자를 두고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 개인정보보호책임자에게 연락주시기 바랍니다. 문의하신 사항에 대해서 신속하고 성실하게 답변해드리겠습니다') }}</p>
        <p>{{ $t('[개인정보보호 책임자]') }}</p>
        <p>{{ $t('이름 : 김재우') }}</p>
        <p>{{ $t('소속 : ㈜팡스카이 이사') }}</p>
        <p>E-MAIL : zeus69@panggame.com</p>
        <br>
        <p>{{ $t('[개인정보관리 담당부서]') }}</p>
        <p>{{ $t('부서 : 서비스운영팀') }}</p>
        <p>{{ $t('전화번호 : 070-4658-9412') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 13 조 청소년보호를 위한 활동') }}</p>
        <p>{{ $t('회사는 각종 청소년 유해정보로부터 청소년을 보호하고자 관련법에 따라 만 19세미만의 청소년이 유해정보에 접근할 수 없도록 청소년보호정책을 마련하여 시행하고 있습니다. 또 회사는 청소년의 건전한 성장을 저해하는 음란, 불법 유해정보와 비윤리적, 반사회적 행위에 대해서는 엄격하게 제재하기 위하여 다음과 같이 활동하고 있습니다.') }}</p>
        <p>{{ $t('1. 청소년유해정보로부터의 청소년보호계획의 수립') }}</p>
        <p>{{ $t('2. 청소년유해정보에 대한 청소년 접근제한 및 관리조치') }}</p>
        <p>{{ $t('3. 정보통신업무 종사자에 대한 청소년유해정보로부터의 청소년보호를 위한 교육') }}</p>
        <p>{{ $t('4. 청소년유해정보로 인한 피해상담 및 고충처리') }}</p>
        <p>{{ $t('5. 그 밖에 청소년유해정보로부터 청소년을 보호하기 위하여 필요한 사항') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 14 조 개인정보 관련 신고 및 분쟁조정') }}</p>
        <p>{{ $t('개인정보에 대한 상담이 필요한 경우에는 다음의 곳으로 문의하시길 바랍니다.') }}</p>
        <p>{{ $t('- 개인정보침해신고센터 전화: 1336, 홈페이지: www.cyberprivacy.or.kr') }}</p>
        <p>{{ $t('- 개인정보분쟁조정위원회 전화: 02-405-4747, 홈페이지: www.kopico.or.kr') }}</p>
        <p>{{ $t('- 경찰청 사이버테러대응센터 홈페이지: www.ctrc.go.kr') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('제 15 조 정책 변경에 따른 공지의무') }}</p>
        <p>{{ $t('법령 정책 또는 보안기술의 변경에 따라 내용의 추가 삭제 및 수정이 있을 시에는 변경되는 개인정보처리방침을 시행하기 최소 7일전에 서비스 초기화면을 통해 변경이유 및 내용 등을 공지하도록 하겠습니다.') }}</p>
      </div>
      <div class="content-item">
        <p class="item">{{ $t('부칙') }}</p>
        <p>{{ $t('본 개인정보처리방침은 2017년12월07일부터 시행됩니다.') }}</p>
        <p>{{ $t('2016년 05월 09일부터 시행되던 종전의 정책은 본 정책으로 대체합니다.') }}</p>
      </div> 
    </div>
  </div>
  <div v-else>
    <Privacykr />
  </div>
</template>

<script>
import Privacykr from "./PrivacyKr.vue"
export default {
  name:"privacy",
  components: {Privacykr},
  props:['pLang'],
  mounted(){
    console.log("Language set to: "+ this.$i18n.locale);
  },
  watch: {
    pLang: function (val) {       
      console.log("Privcay language setting to: "+val);   // 接收父组件的值
    }
  },
}
</script>

<style lang="scss" scoped>
.privacy{
  margin: 150px auto 50px auto;
  color: #666666;
}
.privacy .privacy-title{
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 767px){
  #raw-html{
    max-width: 100%;
  }
  .privacy{
    margin: 100px auto 50px auto;
  }
  .privacy .privacy-title{
    margin-bottom: 0px;
  }
}
.privacy .privacy-content .content-item{
  border-top: 1px solid #dadada;
  padding: 10px 0;
}
.privacy .privacy-content .content-item:nth-child(1){
  border-top: 1px solid #fff;
}
.privacy .privacy-content .content-item p{
  margin-bottom: 5px;
  font-size: 12px;
}
.privacy .privacy-content .content-item .item{
  font-size: 14px;
  font-weight: bold;
  margin: 15px 0 10px 0;
}
.text-indent{
  text-indent: 1em;
}
th,td{
  text-align: center;
  padding: 0 10px;
}
</style>
